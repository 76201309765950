<template>
  <div class="informatlon">
    <div class="informatlon_hesder">
      <img src="../../src/assets/8.png" alt />
    </div>
    <div class="informatlon_center">
      <div class="inuput_list">
        <div class="shoool_name">
          <div class="title">{{ schoolName }}</div>
        </div>
        <!-- 没有数据时 -->
        <div v-if="!judgeinformtype ">
          <van-form @submit="onSubmit">
            <van-field v-model="formValue.name" name="姓名" label="姓名" placeholder="请输入姓名" required />
            
            <van-field name="radio" label="性别" required>
              <template #input >
                <van-radio-group v-model="formValue.radio" direction="horizontal" style="margin-top: 13px;">
                  <van-radio name="0" icon-size="18px">女</van-radio>
                  <van-radio name="1" icon-size="18px">男</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="formValue.phone"
              name="电话"
              label="电话"
              placeholder="请输入手机号"
              required
            />
            <van-field
              readonly
              clickable
              name="班级"
              :value="formValue.classes"
              label="班级"
              placeholder="请选择班级"
              required
              @click="showPicker = true"
            />
            <div class="vanbtn" v-if="!judgeinformtype ">
              <van-button  block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
        <!-- 有数据时 -->
          <!-- 有数据时的页面 -->
      <div class="nomedata" v-else>
        <div class="nomedata_list flex">
          <div>姓名：</div>
          <div>{{ judgeinform.title }}</div>
        </div>
        <div class="nomedata_list flex">
          <div>性别：</div>
          <div>{{ judgeinform.sex=='0'?'女':'男' }}</div>
        </div>
        <div class="nomedata_list flex">
          <div>电话:</div>
          <div>{{ judgeinform.phone }}</div>
        </div>
        <div class="nomedata_list flex noneborder">
          <div>班级：</div>
          <div>{{ judgeinform.classes }}</div>
        </div>
      </div>
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { searchCode, studentAdd } from "../api/home.js";
export default {
  data() {
    return {
      schoolName: "",
      judgeinformtype: false,
      judgeinform: {}, //判断是否有信息
      codedata: "", //获取扫码进去的code
      formValue: {
        name: "", //姓名
        phone: "", //电话
        classes: "", //班级
        radio: "0" //性别
      },
      columns: [
        // 第一列
        {
          values: ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"],
          defaultIndex: 0
        },
        // 第二列
        {
          values: ["一班", "二班", "三班", "四班", "五班", "六班"],
          defaultIndex: 0
        }
      ],
      showPicker: false
    };
  },
  mounted() {
    this.codedata = this.$route.query.code;
    this.queryData(this.codedata)
    console.log(this.$route.query,'------------')
  },
  created() {
    
    console.log(this.$route.query,'------------')
    this.codedata = this.$route.query.code;
    this.queryData(this.codedata)
  },
  methods: {
    // 选择班级
    onConfirm(value) {
      this.formValue.classes = value[0] + value[1];
      this.showPicker = false;
    },
    // 查询数据
    queryData(code) {
      searchCode({ code: code }).then(res => {
        if (res.code == 200) {
          this.judgeinform = res.data;
          this.schoolName=res.data.school_name
          if (res.data.title) {
            this.judgeinformtype = true;
          } else {
            this.judgeinformtype = false;
          }
        }
      });
    },
    // 提交
    onSubmit(values) {
      if (this.formValue.name == "") {
        this.$toast("请输入姓名");
        return;
      }
      if (this.formValue.phone == "") {
        this.$toast("请输入电话");
        return;
      } else if (this.formValue.phone !== "") {
        var phonePattern = /^[1][3-9]\d{9}$/;
        if (!phonePattern.test(this.formValue.phone)) {
          this.$toast("请输入有效的手机号码！");
          return;
        }
      }
      if (this.formValue.classes == "") {
        this.$toast("请选择班级");
        return;
      }
      let params = {
        code: this.codedata,
        name: this.formValue.name,
        phone: this.formValue.phone,
        classes: this.formValue.classes,
        sex: this.formValue.radio
      };
      studentAdd(params).then(res => {
        if (res.code == 200) {
          this.$toast("提交成功");
          this.formValue = {
            name: "",
            phone: "",
            address: "",
            classes: "", //行业的
            radio: "0" //性别
          };
          setTimeout(() => {
            this.queryData(this.codedata);
          }, 1000);
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
  align-items: center;
}
.mt60 {
  margin-top: 10%;
}
.mt20 {
  margin-top: 20px;
}
.informatlon {
    width: 400px;
    margin: auto;
    background: rgba(255, 255, 255, 1);
box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    position: relative;
    .informatlon_hesder {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .informatlon_center {
      position: absolute;
      top: 205px;
      width: 93%;

      left: 50%;
      transform: translate(-50%, 0%);

      .inuput_list {
        width: 100%;
        height: 350px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        .shoool_name {
          margin: auto;
          width: 92.5%;
          height: 95px;
         display: flex;
         align-items: center;
         justify-content: center;
          border-bottom: 1px solid rgba(245, 245, 245, 1);
          .title{
            margin: auto;
            width: 50%;
            text-align: center;
            font-size: 20px;
            color: rgba(0, 0, 0, 1);
          }
        }
        .nomedata{
          width: 92.5%;
          margin: auto;
          .nomedata_list{
            padding: 0px 20px;
            box-sizing: border-box;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid rgba(245, 245, 245, 1);
            color: rgba(51, 51, 51, 1);
          }
          .noneborder{
            border-bottom:none,
          }
        }
      }
    }
    .vanbtn {
      position: absolute;
      width: 100%;
      bottom: -200px;

      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
::v-deep .van-field__label {
  width: 3rem !important;
  color: rgba(51, 51, 51, 1) !important;
}
::v-deep .van-cell {
  font-size: 16px;
  line-height: 50px !important;
  padding: 10px 16px 0px 40px;
}
::v-deep .van-cell--required::before {
  position: absolute;
  left: 27px;
  color: #ee0a24;
  font-size: 14px;
  content: "*";
}
@media (max-width: 768px) {
  .informatlon {
    width: 100vw;
    position: relative;
    .informatlon_hesder {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .informatlon_center {
      position: absolute;
      top: 205px;
      width: 93%;

      left: 50%;
      transform: translate(-50%, 0%);

      .inuput_list {
        width: 100%;
        height: 350px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        .shoool_name {
          margin: auto;
          width: 92.5%;
          height: 95px;
         display: flex;
         align-items: center;
         justify-content: center;
          border-bottom: 1px solid rgba(245, 245, 245, 1);
          .title{
            margin: auto;
            width: 50%;
            text-align: center;
            font-size: 20px;
            color: rgba(0, 0, 0, 1);
          }
        }
        .nomedata{
          width: 92.5%;
          margin: auto;
          .nomedata_list{
            padding: 0px 20px;
            box-sizing: border-box;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid rgba(245, 245, 245, 1);
            color: rgba(51, 51, 51, 1);
          }
          .noneborder{
            border-bottom:none,
          }
        }
      }
    }
    .vanbtn {
      position: absolute;
      width: 100%;
      bottom: -200px;

      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}
</style>
