//引入
import axios from "./axios.js"; //路劲自己修改
// 查询接口
export const searchCode = (params) => {
    return axios({
        url: "home/link/search_code",
        method: "post" ,  
        data: params
    })
}
// 添加接口
export const  studentAdd= (params) => {
    return axios({
        url: "home/link/student_add",
        method: "post" ,  
        data: params
    })
}


                   
